import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pet } from 'src/app/models/pet';
import { LoginService } from '../login/login.service';
import {environment} from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PetService {

  headers;
  constructor(
    private http: HttpClient,
    private loginservice: LoginService
  ) {
    this.headers = this.loginservice.getGeneralHeaders();
  }

  create(pet: Pet) {
    let params = JSON.stringify(pet);
    return this.http.post(environment.apiServer + `/pet/create`, params, this.headers).pipe(map(res => res));
  }

  createDetails(data) {
    return this.http.post(environment.apiServer + `/medical_history/create`, data, this.headers).pipe(map(res => res));
  }

  sendUrls(data, id): Observable<ArrayBuffer> {
    return this.http.put(environment.apiServer + `/medical_history/update_by_pet?id=${id}`, data, this.headers)
      .pipe(map(res => res));
  }

  getPet(ownerId: number) {
    return this.http.get<Array<Pet>>(environment.apiServer + `/pet/get_by_owner?owner_dni=${ownerId}`);
  }

  getBreeds(specie):Observable<any> {
    return this.http.get(environment.apiServer + `/option/get_breeds?specie=${specie}`);
  }

  getSpecies():Observable<any> {
    return this.http.get(environment.apiServer + '/option/get_species');
  }

  getAttitudes():Observable<any> {
    return this.http.get(environment.apiServer + '/option/get_attitude');
  }

  getHydration():Observable<any> {
    return this.http.get(environment.apiServer + '/option/get_hydration');
  }

  getBodyCondition():Observable<any> {
    return this.http.get(environment.apiServer + '/option/get_body_condition');
  }

  getReproductive():Observable<any> {
    return this.http.get(environment.apiServer + '/option/get_reproductive_status');
  }

  getHabitat():Observable<any> {
    return this.http.get(environment.apiServer + '/option/get_habitat');
  }

  getFeedings():Observable<any> {
    return this.http.get(environment.apiServer + '/option/get_feeding');
  }
}
