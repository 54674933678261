import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pet } from 'src/app/models/pet';
import { LoginService } from '../login/login.service';
import { environment } from 'src/environments/environment';
import { PetOwner } from 'src/app/models/petOwner';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PetOwnerService {

  headers;
  constructor(
    private http: HttpClient,
    private loginservice: LoginService
  ) {
    this.headers = this.loginservice.getGeneralHeaders();
  }

  create(petOwner: PetOwner): Observable<any> {
    let params = JSON.stringify(petOwner);
    return this.http.post(environment.apiServer + '/pet_owner/create_owner', params, this.headers).pipe(map(res => res));
  }

  getOwner(ownerId: number, uidVeterinary: string): Observable<any> {
    return this.http.get(environment.apiServer + '/pet_owner/get_by_dni?owner_dni=' + ownerId + '&uid_veterinary=' + uidVeterinary, this.headers).pipe(map(res => res));
  }

  getOwnerByDni(ownerId: number): Observable<any> {
    return this.http.get(environment.apiServer + `/pet_owner/get_by_dni?owner_dni=${ownerId}&uid_veterinary=not_apply`, this.headers).pipe(map(res => res));
  }
}
