export class Message {
  title?: string;
  body?: string;
  buttons_options?: MessageButtonAction[];
}

export class MessageButtonAction {
  title: string;
  url: {
    link: string,
    queryParams?: object,
  };
  action?: () => void;
}
